<template>
  <div class="body">
    <div class="hubs-header">
      <div class="hubs-title">{{ $t("nav.hubs") }}</div>
      <div class="choose_option">
        <a
          class="layout__link__filters"
          :class="{ layout__link_visit: selectedSection === 'add' }"
          @click="showSection('add')"
        >
          <img src="@/assets/add.svg" alt="Добавить" class="icon" />
          {{ $t("hubs.add_btn") }}
        </a>
        <a
          class="layout__link__filters"
          :class="{ layout__link_visit: selectedSection === 'filters' }"
          @click="showSection('filters')"
        >
          <img src="@/assets/filters.svg" alt="фильтр" class="icon" />
          {{ $t("hubs.filters") }}
        </a>
      </div>
    </div>

    <div
      class="text-fields-block block-wide"
      v-if="
        selectedSection === 'add' &&
        ($auth.check({ permissions: 'add_hubs' }) ||
          $auth.check({ name: 'admin' }))
      "
    >
      <form @submit.prevent="confirm_add">
        <hubs-text-fields ref="add_hub" v-model="new_hub"></hubs-text-fields>
        <div class="text-fields-block__btn-row">
          <loader v-if="info.loader"></loader>
          <custom-btn
            class="button button_theme_blue"
            type="submit"
            :disabled="new_hub.id || !new_hub.city_id"
            v-else
          >
            {{ $t("hubs.add_btn") }}
          </custom-btn>
          <span
            v-if="empty_cities"
            class="text-fields-block__info-text text_color_red"
          >
            {{ $t("hubs.empty_cities") }}
            <span
              @click="goto_settings()"
              style="text-decoration: underline; cursor: pointer"
            >
              {{ $t("hubs.settings") }}
            </span>
          </span>
          <span
            class="text-fields-block__info-text text_color_green"
            :class="{ text_color_red: info.error }"
          >
            {{ info_text }}
          </span>
        </div>
      </form>
    </div>

    <div class="hubs__filter_row" v-if="selectedSection === 'filters'">
      <div class="hubs__filter-block">
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('hubs.headers.city')"
          name="city"
          :items="city_filters"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('hubs.headers.pos')"
          name="pos_name"
          :items="pos_filters"
          :disabled="pos_disabled"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('hubs.headers.hub_status')"
          name="hub_status"
          :items="status_filters"
          @change="set_filter"
        ></select-filter>
        <span
          class="hubs__filter-block-clear-btn"
          :class="{
            'hubs__filter-block-clear-btn_disabled': disabled_clear_btn,
          }"
          @click="clear_filters"
        >
          {{ $t("team.clear_filters") }}
        </span>
      </div>
    </div>

    <div class="hubs__table block-wide">
      <hubs-table
        :header_items="headers"
        :items="hubs"
        @change_sorted="change_sorted"
      ></hubs-table>
    </div>

    <confirm-dialog
      :enable="show_confirm"
      src="confirm_reset.svg"
      :button_text="$t('hubs.confirm_add_button')"
      :text="$t('hubs.confirm_add_text')"
      text_width="300"
      @cancel="show_confirm = false"
      @confirm="add_hub"
    ></confirm-dialog>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import HubsTable from "@/components/components/hubsTable";
import HubsTextFields from "@/components/components/hubsTextFields";
import Loader from "@/components/recurringComponents/loader";
import ConfirmDialog from "@/components/recurringComponents/confirmDialog";
import SelectFilter from "@/components/recurringComponents/selectFilter.vue";

export default {
  name: "hubs",
  components: {
    SelectFilter,
    ConfirmDialog,
    Loader,
    HubsTextFields,
    HubsTable,
    CustomBtn,
  },
  data() {
    return {
      selectedSection: null,
      show_confirm: false,
      empty_cities: false,
      info: {
        error: false,
        type: "",
        loader: false,
      },
      new_hub: {
        hub_id: "",
        pos_id: "",
        city_id: "",
        address: "",
      },
      headers: [
        {
          name: "hub_id",
          title: this.$i18n.t("hubs.headers.hub_id"),
          sorted: 1,
        },
        {
          name: "local_ip",
          title: this.$i18n.t("hubs.headers.local_ip"),
          sorted: 0,
        },
        {
          name: "pos_name",
          title: this.$i18n.t("hubs.headers.pos"),
          sorted: 0,
        },
        {
          name: "city_name",
          title: this.$i18n.t("hubs.headers.city"),
          sorted: 0,
        },
        {
          name: "hub_status",
          title: this.$i18n.t("hubs.headers.hub_status"),
          sorted: 0,
        },
        {
          name: "badges",
          title: this.$i18n.t("hubs.headers.badges"),
          sorted: 0,
        },
        {
          name: "timezone",
          title: this.$i18n.t("hubs.headers.timezone"),
          sorted: 0,
        },
        {
          name: "fw_ver",
          title: this.$i18n.t("hubs.headers.fw_ver"),
          sorted: 0,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("load_hubs");

    if (this.selected_filters.city.length != 0) {
      this.selected_filters.city = [];
    };

    if (this.selected_filters.pos_name.length != 0) {
      this.selected_filters.pos_name = [];
    };

    if (this.$store.getters.ALL_POSES.length == 0){
      this.$store.dispatch("load_all_poses");
    }
    
    this.check_empty_cities();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`hubs.headers.${item.name}`);
      }
    },
    selected_filters: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
    pos_disabled() {
      if (this.selected_filters.city.length === 0) {
        this.selected_filters.pos_name = [];
      }
    },
    pos_filters(newValue) {
      this.selected_filters.pos_name = this.selected_filters.pos_name.filter(
        (x) => newValue.some((item) => item.value === x)
      );
    },
  },
  computed: {
    filters() {
      return {
        city: this.$store.getters.FILTERS.city || [],
        pos_name: this.$store.getters.FILTERS.pos_name || [],
        hub_status: this.$store.getters.FILTERS.hub_status || [],
      };
    },
    selected_filters() {
      return this.$store.getters.SELECTED_FILTERS;
    },
    pos_disabled() {
      return this.selected_filters.city.length === 0;
    },
    disabled_button() {
      return !this.$store.getters.HUBS?.some((x) => x.pos.length > 0);
    },

    city_filters() {
      var all_poses = this.$store.getters.ALL_POSES;
      

      const filters = Array.from(
        new Set(
          all_poses.map((pos) => pos.city.name)
        )
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });

      // console.log("Города:", filters);
      return filters;
    },

    pos_filters() {
      var all_poses = this.$store.getters.ALL_POSES;
      var cityFilters = this.selected_filters.city;
      var filters = [];
      
      for (let i = 0; i < all_poses.length; ++i) {
        if (cityFilters.includes(all_poses[i].city.name)) {
          filters.push({title: all_poses[i].name, value: all_poses[i].name, selected: false})
        }
      };

      // console.log("POS:", filters);
      return filters;
    },
    status_filters() {
      return [
        {
          value: "Online",
          text: this.$t("status.info.online"),
        },
        {
          value: "Offline",
          text: this.$t("status.info.offline"),
        },
      ].map((item) => {
        return { title: item.text, value: item.value, selected: false };
      });
    },
    disabled_clear_btn() {
      return (
        this.selected_filters.city.length === 0 &&
        this.selected_filters.pos_name.length === 0 &&
        this.selected_filters.hub_status.length === 0
      );
    },
    hubs() {
      const hubsData = this.$store.getters.HUBS;
      const cityFilter = this.selected_filters.city || [];
      const posFilter = this.selected_filters.pos_name || [];
      const statusFilter = this.selected_filters.hub_status || [];
      console.log(cityFilter);
      const filteredHubs = hubsData.filter((hub) => {
        const cityMatch =
          cityFilter.length === 0 || cityFilter.includes(hub.pos.city.name);
        const posMatch =
          posFilter.length === 0 || posFilter.includes(hub.pos.name);
        const statusMatch =
          statusFilter.length === 0 || statusFilter.includes(hub.status);

        return cityMatch && posMatch && statusMatch;
      });

      return filteredHubs.map((hub) => {
        const upload_mode = hub.upload_mode === "instant";
        const recording = hub.recording_mode === "continuous";

        return {
          id: hub.id,
          hub_id: hub.hub_id,
          local_ip: hub.ip,
          pos_id: hub.pos.id,
          pos: hub.pos,
          pos_name: hub.pos.name,
          city: hub.pos.city.id,
          city_id: hub.pos.city?.id,
          city_name: hub.pos.city?.name,
          address: hub.pos.address,
          hub_status: hub.status,
          badges: hub.badge_connected,
          timezone: "UTC " + hub.timezone,
          fw_ver: hub.firmware,
          upload_mode: upload_mode,
          motion: hub.motion_detection_state,
          recording: recording,
          upload_time: hub.upload_time ? hub.upload_time : ":",
          battery: hub.battery_ready_to_use,
        };
      });
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_mac":
          return this.$i18n.t("hubs.loader.no_mac");
        case "progress":
          return this.$i18n.t("hubs.loader.progress");
        case "failed":
          return this.$i18n.t("hubs.loader.failed");
        case "success":
          return this.$i18n.t("hubs.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    showSection(section) {
      this.selectedSection = this.selectedSection === section ? null : section;
    },
    goto_settings() {
      this.$router.push("/settings");
    },
    check_empty_cities() {
      this.empty_cities = false;
      setTimeout(() => {
        if (this.$store.getters.CITIES.length === 0) {
          this.empty_cities = true;
        }
      }, 1000);
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.hubs.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    set_selected_city() {},
    set_filter(filter) {
      this.$store.dispatch("set_filters", filter);
    },
    validate() {
      this.info.error = false;
      this.info.text = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
          this.reset_error_message();
        }
      }
      return !this.info.error;
    },
    add_hub() {
      if (this.validate()) {
        this.show_confirm = false;
        this.info.loader = true;
        this.info.type = "progress";
        delete this.new_hub.city_id;
        delete this.new_hub.pos_address;
        delete this.new_hub.address;
        this.$store
          .dispatch("add_hub", this.new_hub)
          .then(() => {
            this.info.type = "success";
            this.new_hub = {
              hub_id: "",
              pos_id: "",
              city_id: "",
              pos_address: "",
              address: "",
            };
          })
          .catch((err) => {
            this.info.error = true;
            this.info.type = err.response?.data.errors.find((x) =>
              x.message.includes("mac")
            )
              ? "no_mac"
              : "failed";
          })
          .finally(() => {
            this.info.loader = false;
            this.reset_error_message();
          });
      }
    },
    clear_filters() {
      if (!this.disabled_clear_btn) {
        this.$store.dispatch("clear_filters").then(() => {
          this.$store.dispatch("load_hubs");
        });
      }
    },
    reset_error_message() {
      setTimeout(() => {
        this.info.type = "";
        this.info.error = false;
      }, [3000]);
    },
    confirm_add() {
      if (this.validate()) {
        this.show_confirm = true;
      }
    },
  },
};
</script>

<style scoped></style>
